import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Item from 'components/gallery/item';
import { Container } from './gallery.css';
import { DropDown } from './gallery.css';
import Select from 'react-select';
import IOExample from 'components/io-example';

const Gallery = ({ items }) => {
  return (
    <Container>
      {/* {items.map((item, i) => (
        <Item {...item} key={i} />
      ))} */}
      <div className="elfsight-app-c4482e39-c9f1-4cdd-9843-b735a9342afb"></div>
    </Container>
  );
};

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery;
