import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  height: 50%;
  padding: 0;
  margin-left: 10em;
  margin-right: 10em;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${MEDIA.TABLET`
    display: block;
    margin: 1em;
  `};
`;

export const DropDown = styled.div`
  color: #000;
`;
